<template>
	<!-- 添加机器人 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<router-link class="btn" to="/robot">{{$t('aside.list')[2]}}</router-link>
				<i class="iconfont2 icon-right_arrow"></i>
				<span>{{$t('robot.addrobot')}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<template v-if="robotList.length">
						<!-- 个人版 -->
						<div class="lvTitle" v-show="web_type==0">{{$t('level[0]')}}</div>
						<div class="listbox" v-show="web_type==0">
							<div class="item" v-for="index in 5" :key="index" @click="handleOpen(index,1)" :class="{active: activeIndex == '1-'+index}">
								<div class="top" :class="{disabled: type < 1}">
									<div class="icon">
										<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+index+'.png')" />
										<img v-else :src="require('@/assets/images/robot2/list_robot-'+index+'.png')" />
									</div>
									<div class="title">
										<span>{{$t('robot.list['+(index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(index-1)"></i></span>
									</div>
								</div>
								<div class="btn" v-if="type < 1" @click="toRenew">{{$t('common.toOpen')}}</div>
							</div>
						</div>

						<!-- 企业版 -->
						<div class="lvTitle" v-show="web_type==0">{{$t('level[1]')}}</div>
						<div class="listbox" v-show="web_type==0">
							<div class="item" v-for="index in 6" :key="index" @click="handleOpen(5+index,2)" :class="{active: activeIndex == '2-'+(5+index)}">
								<div class="top" :class="{disabled: type < 2}">
									<div class="icon">
										<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(5+index)+'.png')" />
										<img v-else :src="require('@/assets/images/robot2/list_robot-'+(5+index)+'.png')" />

										<svg class="new" v-if="index==6" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
											<g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
												<g id="机器人" transform="translate(-693.000000, -168.000000)" fill-rule="nonzero">
													<g id="new" transform="translate(693.000000, 168.000000)">
														<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="40" height="40"></rect>
														<path d="M32.4166667,12.25 C33.7973786,12.25 34.9166667,13.3692881 34.9166667,14.75 L34.9166667,23.0833333 C34.9166667,24.4640452 33.7973786,25.5833333 32.4166667,25.5833333 L10.2366667,25.5833333 L6.575,29.1666667 L6.575,25 L6.58333332,25 L6.58333332,14.75 C6.58333332,13.3692881 7.70262145,12.25 9.08333332,12.25 L32.4166667,12.25 Z M27.8333333,15.3083333 L25.8333333,15.3083333 L24.9166667,20.7208333 L24.1666667,15.3875 L22.1666667,15.3875 L23.5,22.2108333 L26.1666667,22.2108333 L26.9166667,17.6616667 L27.5833333,22.2108333 L30.25,22.2108333 L31.5833333,15.3875 L29.5833333,15.3875 L28.9166667,20.7991667 L27.8333333,15.3091667 L27.8333333,15.3083333 Z M21.9166667,15.3083333 L17,15.3083333 L17,22.1325 L21.9166667,22.1325 L21.9166667,20.7208333 L18.8333333,20.7208333 L18.8333333,19.23 L21.5,19.23 L21.5,17.9758333 L18.8333333,17.9758333 L18.8333333,16.6425 L21.9166667,16.6425 L21.9166667,15.3091667 L21.9166667,15.3083333 Z M13.4166667,15.3083333 L10.6666667,15.3083333 L10.6666667,22.1325 L12.5,22.1325 L12.5,17.1125 L13.75,22.1325 L16.5,22.1325 L16.5,15.3083333 L14.6666667,15.3083333 L14.6666667,20.5625 L13.4166667,15.3083333 L13.4166667,15.3083333 Z" id="形状" fill="#E65B1B"></path>
													</g>
												</g>
											</g>
										</svg>
									</div>
									<div class="title">
										<span>{{$t('robot.list['+(5+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(5+index-1)"></i></span>
									</div>
								</div>
								<div class="btn" v-if="type < 2" @click="toRenew">{{$t('common.toOpen')}}</div>
							</div>
						</div>
						
						<!-- 旗舰版 -->
						<div class="lvTitle" v-show="web_type==0">{{$t('level[2]')}}</div>
						<div class="listbox" v-show="web_type==0">
							<div class="item" v-for="index in 4" :key="index" @click="handleOpen(11+index,4)" :class="{active: activeIndex == '4-'+(11+index)}">
								<div class="top" :class="{disabled: type < 4}">
									<div class="icon">
										<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(11+index)+'.png')" />
										<img v-else :src="require('@/assets/images/robot2/list_robot-'+(11+index)+'.png')" />
									</div>
									<div class="title">
										<span>{{$t('robot.list['+(11+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(11+index-1)"></i></span>
									</div>
								</div>
								<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.toOpen')}}</div>
							</div>
						</div>

						<!-- dex版 -->
						<div class="lvTitle" v-show="web_type==1">{{$t('level[3]')}}</div>
						<div class="listbox" v-show="web_type==1">
							<div class="item" v-for="index in 6" :key="index" @click="handleOpen(15+index,4)" :class="{active: activeIndex == '4-'+(15+index)}">
								<div class="top" :class="{disabled: type < 4}">
									<div class="icon">
										<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(15+index)+'.png')" />
										<img v-else :src="require('@/assets/images/robot2/list_robot-'+(15+index)+'.png')" />
									</div>
									<div class="title">
										<span>{{$t('robot.list['+(15+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(15+index-1)"></i></span>
									</div>
								</div>
								<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.toOpen')}}</div>
							</div>
						</div>

						<!-- NFT版 -->
						<div class="lvTitle" v-show="web_type==2">{{$t('level[4]')}}</div>
						<div class="listbox" v-show="web_type==2">
							<div class="item" v-for="index in 2" :key="index" @click="handleOpen(21+index,4)" :class="{active: activeIndex == '4-'+(21+index)}">
								<div class="top" :class="{disabled: type < 4}">
									<div class="icon">
										<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(21+index)+'.png')" />
										<img v-else :src="require('@/assets/images/robot2/list_robot-'+(21+index)+'.png')" />
									</div>
									<div class="title">
										<span>{{$t('robot.list['+(21+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(21+index-1)"></i></span>
									</div>
								</div>
								<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.toOpen')}}</div>
							</div>
						</div>
					</template>
					<el-empty v-else :image="emptyImg" :image-size="120" />
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<template v-if="robotList.length">
					<!-- 个人版 -->
					<div class="lvTitle" v-show="web_type==0">
						<div>{{$t('level[0]')}}</div>
						<div class="btn" v-if="type < 1" @click="toRenew">{{$t('common.open')}}</div>
					</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="index in 5" :key="index" @click="handleOpen(index,1)" :class="{active: activeIndex == '1-'+index}">
							<div class="top" :class="{disabled: type < 1}">
								<div class="icon">
									<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+index+'.png')" />
									<img v-else :src="require('@/assets/images/robot2/list_robot-'+index+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list['+(index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(index-1)"></i></span>
								</div>
							</div>
						</div>
					</div>

					<!-- 企业版 -->
					<div class="lvTitle" v-show="web_type==0">
						<div>{{$t('level[1]')}}</div>
						<div class="btn" v-if="type < 2" @click="toRenew">{{$t('common.open')}}</div>
					</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="index in 6" :key="index" @click="handleOpen(5+index,2)" :class="{active: activeIndex == '2-'+(5+index)}">
							<div class="top" :class="{disabled: type < 2}">
								<div class="icon">
									<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(5+index)+'.png')" />
									<img v-else :src="require('@/assets/images/robot2/list_robot-'+(5+index)+'.png')" />

									<svg class="new" v-if="index==6" width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
										<g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
											<g id="机器人" transform="translate(-693.000000, -168.000000)" fill-rule="nonzero">
												<g id="new" transform="translate(693.000000, 168.000000)">
													<rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="40" height="40"></rect>
													<path d="M32.4166667,12.25 C33.7973786,12.25 34.9166667,13.3692881 34.9166667,14.75 L34.9166667,23.0833333 C34.9166667,24.4640452 33.7973786,25.5833333 32.4166667,25.5833333 L10.2366667,25.5833333 L6.575,29.1666667 L6.575,25 L6.58333332,25 L6.58333332,14.75 C6.58333332,13.3692881 7.70262145,12.25 9.08333332,12.25 L32.4166667,12.25 Z M27.8333333,15.3083333 L25.8333333,15.3083333 L24.9166667,20.7208333 L24.1666667,15.3875 L22.1666667,15.3875 L23.5,22.2108333 L26.1666667,22.2108333 L26.9166667,17.6616667 L27.5833333,22.2108333 L30.25,22.2108333 L31.5833333,15.3875 L29.5833333,15.3875 L28.9166667,20.7991667 L27.8333333,15.3091667 L27.8333333,15.3083333 Z M21.9166667,15.3083333 L17,15.3083333 L17,22.1325 L21.9166667,22.1325 L21.9166667,20.7208333 L18.8333333,20.7208333 L18.8333333,19.23 L21.5,19.23 L21.5,17.9758333 L18.8333333,17.9758333 L18.8333333,16.6425 L21.9166667,16.6425 L21.9166667,15.3091667 L21.9166667,15.3083333 Z M13.4166667,15.3083333 L10.6666667,15.3083333 L10.6666667,22.1325 L12.5,22.1325 L12.5,17.1125 L13.75,22.1325 L16.5,22.1325 L16.5,15.3083333 L14.6666667,15.3083333 L14.6666667,20.5625 L13.4166667,15.3083333 L13.4166667,15.3083333 Z" id="形状" fill="#E65B1B"></path>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<div class="title">
									<span>{{$t('robot.list['+(5+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(5+index-1)"></i></span>
								</div>
							</div>
						</div>
					</div>
					
					<!-- 旗舰版 -->
					<div class="lvTitle" v-show="web_type==0">
						<div>{{$t('level[2]')}}</div>
						<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div>
					</div>
					<div class="listbox" v-show="web_type==0">
						<div class="item" v-for="index in 4" :key="index" @click="handleOpen(11+index,4)" :class="{active: activeIndex == '4-'+(11+index)}">
							<div class="top" :class="{disabled: type < 4}">
								<div class="icon">
									<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(11+index)+'.png')" />
									<img v-else :src="require('@/assets/images/robot2/list_robot-'+(11+index)+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list['+(11+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(11+index-1)"></i></span>
								</div>
							</div>
						</div>
					</div>

					<!-- dex版 -->
					<div class="lvTitle" v-show="web_type==1">
						<div>{{$t('level[3]')}}</div>
						<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div>
					</div>
					<div class="listbox" v-show="web_type==1">
						<div class="item" v-for="index in 6" :key="index" @click="handleOpen(15+index,4)" :class="{active: activeIndex == '4-'+(15+index)}">
							<div class="top" :class="{disabled: type < 4}">
								<div class="icon">
									<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(15+index)+'.png')" />
									<img v-else :src="require('@/assets/images/robot2/list_robot-'+(15+index)+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list['+(15+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(15+index-1)"></i></span>
								</div>
							</div>
						</div>
					</div>
					
					<!-- NFT版 -->
					<div class="lvTitle" v-show="web_type==2">
						<div>{{$t('level[4]')}}</div>
						<div class="btn" v-if="type < 4" @click="toRenew">{{$t('common.open')}}</div>
					</div>
					<div class="listbox" v-show="web_type==2">
						<div class="item" v-for="index in 2" :key="index" @click="handleOpen(21+index,4)" :class="{active: activeIndex == '4-'+(21+index)}">
							<div class="top" :class="{disabled: type < 4}">
								<div class="icon">
									<img v-if="theme!='light'" :src="require('@/assets/images/robot/list_robot-'+(21+index)+'.png')" />
									<img v-else :src="require('@/assets/images/robot2/list_robot-'+(21+index)+'.png')" />
								</div>
								<div class="title">
									<span>{{$t('robot.list['+(21+index-1)+'].name')}}<i class="iconfont icon-tips" @click.stop="openTip(21+index-1)"></i></span>
								</div>
							</div>
						</div>
					</div>
				</template>
				<el-empty v-else :image="emptyImg" :image-size="120" />
			</div>
		</template>

		<!-- 一级添加弹窗 -->
		<el-dialog :title="$t('common.addTile')+$t('robot.list['+(titleIndex-1)+'].name')" :visible.sync="showSelectBox" :show-close="false" center :before-close="beforeClose">
			<div class="add_form">
				<div class="form form_flex">
					<div class="item">
						<div class="title title1">{{$t('common.exchange')}}</div>
						<div class="select" @click="popExchange = true">
							<input v-model="exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
					<div class="item" v-if="web!='opensea'">
						<div class="title title1">{{$t('common.pair')}}</div>
						<div class="doubletext">
							<input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
							<i class="split"></i>
							<input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
						</div>
					</div>
					<div class="item" v-if="web=='opensea'">
						<div class="title title1">{{$t('common.opensea')}}</div>
						<div class="select">
							<input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="btn" @click="handleCancel1">{{$t('common.back')}}</div>
					<div class="btn submit" @click="toNext">{{$t('common.next')}}</div>
				</div>
			</div>
		</el-dialog>
		<!-- 二级添加弹窗 -->
		<!--  @close="activeIndex = null" -->
		<el-dialog :title="$t('common.addTile')+$t('robot.list['+(titleIndex-1)+'].name')" :visible.sync="showAdd" :show-close="false" center :before-close="beforeClose">

			<robotForm
				ref="robotForm"
				:titleIndex="titleIndex"
				@handleCancel="handleCancel"
				@handleConfirm="handleConfirm(arguments)"
				@openPop="openPop"
				:yenUnit1="yenUnit1"
				:yenUnit2="yenUnit2"
				:exchange="exchange"
				:web="web"
				:isDis="isDis"
			></robotForm>

		</el-dialog>
		<!-- 说明弹窗 -->
		<el-dialog class="w900" :title="$t('robot.list['+explainIndex+'].name')+$t('common.explain')" center :visible.sync="showTips" :show-close="false" @close="activeIndex = null">
			<div class="tips_form" v-html="$t('robot.list['+explainIndex+'].html',[yenUnit2])"></div>
		</el-dialog>
		<!-- 添加成功弹窗 -->
		<!--  @close="activeIndex = null" -->
		<el-dialog class="action_ok_dialog add_ok_dialog" :visible.sync="showSuccess" :show-close="false" :before-close="beforeClose">
			<div class="action_ok">
				<div class="form">
					<div class="icon"><img src="@/assets/images/ok.png" /></div>
					<h3>{{$t('robot.success')}}</h3>
					<p>{{$t('robot.sub')}}</p>
				</div>
				<div class="foot">
					<div class="btn submit" @click="handleNext">{{$t('common.continueAdd')}}</div>
					<router-link class="btn" :to="isDis==1?{path:'/stocks',query:{tab:1}}:'/robot'">{{$t('common.backlist')}}</router-link>
				</div>
			</div>
		</el-dialog>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
		<!-- 分拨资金弹窗 -->
		<subTransfer 
		ref="subTransfer"
		:address_list="address_list"
		:id_list="id_list"
		:chain="chain"
		:token="token"
		:approve_token="approve_token"
		:web="transferWeb"
		:exchange="transferExchange"
		></subTransfer>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'add.styl';
</style>

<script>
	import { Loading } from 'element-ui';
	const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
			
	import ExchangeDialog from '@/components/exchange.vue';
	import robotForm from '@/components/robotForm.vue';
	import subTransfer from '@/components/subTransfer/subTransfer.vue';

	import { mapGetters } from 'vuex';
	import typeFormat from '@/utils/methApi/typeFormat';
	// api
	import { getRobot,setRobot } from '@/api/robot';
	import { dexWalletsApprove} from '@/api/markets';
	export default ({
		name: 'addrobot',
		components: {
			ExchangeDialog,
			robotForm,
			subTransfer
		},
		data() {
			return {
				emptyImg: require('@/assets/images/loading.png'),
				showSelectBox: false,
				showAdd: false,
				showSuccess: false,
				showTips: false,
				activeIndex: null,
				titleIndex:null,
				// 说明选中项
				explainIndex:null,
				
				// 以下数据可自行修改
				robotList: ['这里是机器人型号列表信息'],
				// checked1: false,
				// checked2: false,
				// form: {},

				// 交易所选择
				popExchange: false,
				web: localStorage.getItem('web') || 'bian',
				// 子组件的交易所
				isSonPop:false,
				web2: null,
				sonFormNum:0,
				// 一级弹窗
				exchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
				yenUnit1: localStorage.getItem('yenUnit1') || 'btc',
				yenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
				web_type: localStorage.getItem('web_type') || '0',
				
				// 有权限的机器人数量
				// powerLv:1,// 个人级1、企业级2、旗舰级4、DEX20 // 个人级5、企业级10、旗舰级15、DEX20

				// 弹窗1是否禁用
				isDis:0,

				// 资金划分
				address_list:[],
				id_list:[],
				chain:'',
				token:'',
				approve_token:'',
				transferWeb:'',
				transferExchange:'',
			}
		},
		computed:{
			...mapGetters(['type','account','theme','isMobile']),
		},
		created() {
			this.isDis=this.$route.query.isDis==1?1:0
		},
		mounted() {
			loading.close();
		},
		methods: {
			toRenew(){
				// 请您联系商务客服续费！
				this.$message({
					message: this.$t('tip.renewTip'),
					type: 'info',
					center: true,
					customClass: 'shotMsg',
				});
				// this.$router.push({
				// 	path:'/renew'
				// })
			},
			openTip(index){
				// console.log(index)
				this.explainIndex=index;
				this.showTips = true;
			},
			// 弹窗数据初始化
			init(){
				// 弹窗1
				// this.exchange='';
				// this.yenUnit1='';
				// this.yenUnit2='';
				// 交易所
				// this.web=null;
				this.web2=null;
				this.sonFormNum=0;
				// 弹窗2
				if(this.$refs.robotForm){
					this.$refs.robotForm.init();
				}
			},
			// 打开一级弹窗
			handleOpen(index,num) {
				// 请自行修改需要传递的参数--并进行判断
				if (num > this.type) {
					return;
				} else {
					this.activeIndex = num+'-'+index;
					this.titleIndex=index;
					if(this.isDis==1){
						// 如果手动操盘过来的直接二级弹窗
						this.toNext()
					}else{
						this.showSelectBox=true;
					}
				}
			},
			// 一级弹窗
			handleCancel1(){
				this.activeIndex = null;
				this.showSelectBox=false;
				// 初始化
				this.init()
			},
			beforeClose(done){
				this.activeIndex = null;
				// console.log('beforeClose',this.$refs.robotForm)
				// 初始化
				this.init()
				done()
			},
			toNext(){
				// 非空验证
				if(!this.web){
					this.$message({
						message: this.$t('tip.selectExchange')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(this.web!='opensea'){
					if(!this.yenUnit1||!this.yenUnit2){
						this.$message({
							message: this.$t('tip.enterPair')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						// 获取参数-传递param给组件
						var index=this.titleIndex-1;
						var {type,sub_type}=typeFormat(index)
						getRobot({
							web:this.web,
							currency:this.yenUnit1+'_'+this.yenUnit2,
							account:this.account,
							type:type,
							sub_type:sub_type,
						}).then(res=>{
							this.showSelectBox=false;
							this.showAdd=true;
							if(!!res){
								this.$nextTick(()=>{
									if(this.$refs.robotForm){
										this.$refs.robotForm.paramInit(res.param);
									}
								});
							}else{
								this.$nextTick(()=>{
									if(this.$refs.robotForm){
										this.$refs.robotForm.paramInit('');
									}
								});
							}
						})
					}
				}else{
					// 如果opensea 下一步
					if(!this.yenUnit1){
						this.$message({
							message: this.$t('tip.enterCollect')+'！',
							type: 'error',
							center: true,
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						this.showSelectBox=false;
						this.showAdd=true;
						
						// 已有数据渲染 - 默认清空
						this.$nextTick(()=>{
							if(this.$refs.robotForm){
								this.$refs.robotForm.paramInit('');
							}
						});
					}
				}
			},
			// 二级弹窗
			handleCancel() {
				if(this.isDis==1){
					this.showAdd = false;
				}else{
					this.showAdd = false;
					this.showSelectBox=true;
				}
			},
			// 添加机器人
			handleConfirm(data,approve_type) {
				var param=data[0];
				var addSelectNum=data[1];
				var state=data[2];
				// console.log('param',param,addSelectNum)

				var index=this.titleIndex-1;
				var {type,sub_type}=typeFormat(index,addSelectNum)

				var currency;
				if(this.web!='opensea'){
					currency=this.yenUnit1+'_'+this.yenUnit2
				}else{
					currency=this.yenUnit1
				}
				setRobot({
					web:this.web,
					currency,
					account:this.account,
					type:type,
					sub_type:sub_type,
					param:param,
					state,
					approve_type:approve_type=='again'?'0':'1'
				}).then(res=>{
					if(res.code==2107||(res.code==2109&&res.type=='now')){
						// 未授权
						// 提示授权中。。
						this.$message({
							message: this.$t('tip.processing'),
							type: 'success',
							center: true,
							customClass: 'shotMsg',
						});
						// 授权代币请求
						dexWalletsApprove({
							web:this.web,
							account:this.account,
							approve_token:res.data.replace(',','_'),//代币
							is_master_approve:2,
						}).then(re=>{
							if(re.code==2172&&re.type=='confirm'){
								// 分拨授权
								this.address_list=re.data.address_list
								this.id_list=re.data.id_list
								this.chain=re.data.chain
								this.token=re.data.gas_token
								this.approve_token=re.data.approve_token
								this.transferWeb=this.web
								this.transferExchange=this.exchange
								this.$refs.subTransfer.open()
							}else if(re.code==2172&&re.type=='ignore'){
								// 忽略，重新操作
								this.handleConfirm(data,'again')
							}else{
								// 授权成功
								this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
									confirmButtonText: this.$t('common.confirm'),//'继续操作',
									// showConfirmButton:false,
									center: true,
									showClose: false,
									customClass: 'deleteOk',
									// cancelButtonText: this.$t('common.back'),
									showCancelButton:false,
								})
							}
						})
					}else if(res.code==2109&&res.type=='ignore'){
						// 忽略，重新操作
						this.handleConfirm(data,'again')
					}else if(res.code==2109&&res.type=='confirm'){
						// 分拨授权
						this.address_list=res.data.address_list
						this.id_list=res.data.id_list
						this.chain=res.data.chain
						this.token=res.data.gas_token
						this.approve_token=res.data.approve_token
						this.transferWeb=this.web
						this.transferExchange=this.exchange
						this.$refs.subTransfer.open()
					}else{
						this.showAdd = false;
						// 提交数据成功后
						this.$nextTick(() => {
							this.showSuccess = true;

							// 提交成功初始化
							this.init();
						})
					}
				})
			},
			handleNext() {
				this.showSuccess = false;
				this.activeIndex = null;
				// this.showSelectBox = true;
				this.init();
			},
			
			// 交易所
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if(this.isSonPop){
					this.isSonPop=false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}
			},
			handleSelect(data) {
				if(this.isSonPop){
					this.$refs.robotForm.handleSelect(data,this.sonFormNum)
					// 子组件判定初始化
					this.isSonPop=false;
					this.popExchange = false;
					// 还原交易所数据
					this.web=this.web2;
					this.web2 = null;
				}else{
					this.exchange = data.text;
					this.web=data.web;
					this.web_type=data.web_type;
					this.popExchange = false;
				}
			},
			// 子组件交易所
			openPop(data,num){
				// 暂存原交易所数据
				this.web2 = this.web;
				// 子组件交易所数据展示
				this.web=data.web;

				this.isSonPop=true;
				this.popExchange = true;
				if(num){
					this.sonFormNum=num;
				}
			},
		}
	})
</script>
